import { ExlyModal as __ExlyModal } from "features/Common/modules/ExlyModal/ExlyModal";

/**
 *
 * @deprecated use from features/Common/modules/ExlyModal/ExlyModal.jsx
 *
 */
const ExlyModal = __ExlyModal;

export default ExlyModal;
