export const onboardingFeatureKeys = {
  SEO: {
    SEARCH_RESULTS_PAGE: "seo_search_results_page_onboarding",
    PERFORMANCE_AND_ANALYTICS: "performance_and_analytics",
  },
  SEGMENTS: {
    USER_SEGMENTATION: "user_segmentation",
    DYNAMIC_SEGMENTATION: "dynamic_segmentaion",
    FIRST_EMAIL_USED_SEGMENTAION: "first_email_segmentation",
    FIRST_WA_USED_SEGMENTATION: "first_wa_used_segmentation",
  },
  CREATE_PAYMENT_LINKS: "create_payment_links",
  DRIP_EMAIL_CONTENT: "drip_email_content",
  WHATSAPP_CUSTOM_TEMPLATES: "whatsapp_custom_templates",
  CREATE_CUSTOM_TEMPLATES: "create_custom_templates",
  CRM: {
    TAGS: "tags",
  },
  EMAIL_BROADCAST_PERSONALIZATION: "EMAIL_BROADCAST_PERSONALIZATION",
  ASSESSMENT_TO_EMBED_ACK: "assessment_to_embed_ack",
  FORM_POINTS_TO_REMEMBER_ACK: "form_points_to_remember_ack",
  EVALUATE_EXAM_MARKS_NOTE_SHOWN: "evaluate_exam_marks_note_shown",
  HIDE_EVALUATE_MARKS_NOTE: "hide_evaluate_marks_note",
  COMMUNITY_V2_ONBOARDING_DESKTOP: "branded_community_v2_desktop",
  COMMUNITY_V2_ONBOARDING_MOBILE: "branded_community_v2_mobile",
  WHATSAPP_WHITE_LABEL_INTRODUCED: "white_label_introduced",
  WHATSAPP_WHITE_LABEL_SUCCESS_ONBOARDING_INTRODUCED:
    "white_label_success_introduced",
  POST_INSTALMENT_SECTION_UNLOCKING_ACK: "post_instalment_section_unlocking",
  MANAGE_RECORDINGS_SECTION: "manage_recordings_section",
  DUPLICATE_PROGRESS_TRACKING_FORM: "duplicate_progress_tracking_form",
  KNOWLEDGE_BASE: {
    MY_SCHEDULE: "my_schedule",
    FEEDBACKS: "feedbacks",
    BLOGS: "blogs",
    EXLY_CONNECT: "exly_connect",
    WEBPAGE_LEADS: "webpage_leads",
    SEGMENTS: "segments",
    ALL_CUSTOMERS: "all_customers",
    ALL_TRANSACTIONS: "all_transactions",
    MANAGE_SUBSCRIPTIONS: "manage_subscription",
    MANAGE_BOOKING_QUESTIONS: "manage_booking_questions",
    MANAGE_ORDER_BUMPS: "manage_order_bumps",
    LTO: "lto",
    WHATSAPP_BROADCAST: "whatsapp_broadcast",
    WHATSAPP_TEMPLATES: "whatsapp_templates",
    ADS_MANAGER: "ads_manager",
    EMAIL_CAMPAIGNS: "email_campaigns",
    DRIP_CAMPAIGNS: "drip_campaigns",
    SALES_PAGES: "sales_pages",
    DISCOUNTS: "discounts",
    TRAFFIC_ANALYSIS: "traffic_analysis",
    OFFERINGS: "offerings",
    PLANS: "plans",
    MANAGE_BOOKINGS: "manage_bookings",
    MANAGE_COMMS: "manage_comms",
  },
  HAS_SEEN_CT_WELCOME_MODAL: "has_seen_welcome_modal",
  HAS_SHARED_SALES_PAGE_LINK: "has_shared_sales_page",
  HAS_SEEN_CT_TOUR: "has_seen_ct_tour",
};
