import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: "14px",
    fontWeight: 500,
    color: theme?.palette?.secondary?.main,
    marginBottom: "8px",
  },
  inputRoot: {
    minHeight: "40px",
    padding: "8px 52px 8px 12px !important",
    "& fieldset": {
      border: "1.5px solid rgb(207, 205, 201)",
    },
  },
  endAdornment: {
    right: "2px !important",
  },
  inputFocused: {
    "& fieldset": {
      borderColor: theme?.palette?.primary?.main,
    },
  },
  input: {
    padding: "0 !important",
  },
  autocompleteOption: {
    padding: "0 !important",
  },
  autocompleteGroupLabel: {
    color: theme?.palette?.secondary?.main,
    fontWeight: 500,
    fontSize: "14px",
    paddingLeft: "11px !important",
  },
  error: {
    fontSize: "11px",
    color: theme?.palette?.basic?.danger_red,
  },
  cta: {
    borderRadius: "0 0 4px 4px",
  },
  popupIndicator: {
    marginRight: "3px",
  },
}));
