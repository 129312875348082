import React from "react";
import { Switch } from "@my-scoot/component-library-legacy";

const ExlySwitch = (props) => {
  const { input, onChange, ...restProps } = props;
  const { value, onChange: inputOnChange } = input;

  return (
    <Switch
      checked={value}
      onChange={(event, checked) => {
        if (onChange) onChange(event, checked);
        inputOnChange(checked);
      }}
      {...restProps}
    />
  );
};

export default ExlySwitch;
