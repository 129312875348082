import React from "react";

import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import DashboardRoundedIcon from "@material-ui/icons/DashboardRounded";
import MonetizationOnRoundedIcon from "@material-ui/icons/MonetizationOnRounded";
import SwapHorizRoundedIcon from "@material-ui/icons/SwapHorizRounded";
import { ReactComponent as ListingsIcon } from "../../../assets/images/bottomNavigation/listings.svg";
import { is_empty } from "utils/validations";
import { api } from "data";
import { RESOURCE_KEYS, orgPermissions } from "utils/OrgPermissions";
import {
  DASHBOARD_CONFIG,
  LISTING_CONFIG,
  SALES_PAGES_CONFIG,
  TRANSACTIONS_CONFIG,
} from "features/UILayout/modules/Menu/constants/Menu.constants";

const defaultTabs = [
  {
    label: "Home",
    icon: <HomeRoundedIcon />,
    path: "/mobile-home",
  },
  {
    label: "Offerings",
    icon: <ListingsIcon className="menu-item-selected" />,
    path: "/ScheduleList",
    ...LISTING_CONFIG,
  },
  {
    label: "Dashboard",
    icon: <DashboardRoundedIcon />,
    path: "/Dashboard",
    ...DASHBOARD_CONFIG,
  },
  {
    label: "Sales",
    icon: <MonetizationOnRoundedIcon />,
    path: "/sales-and-marketing",
    ...SALES_PAGES_CONFIG,
  },
  {
    label: "Transactions",
    icon: <SwapHorizRoundedIcon />,
    path: `/${api.transaction_list}`,
    ...TRANSACTIONS_CONFIG,
  },
];

export const getTabConfig = (permissions) => {
  if (is_empty(permissions)) return defaultTabs;

  const hideListings = permissions["create_edit_listing"];
  const hideDashboard = permissions["dashboard"];

  const hideSales =
    permissions["payment_links"] &&
    is_empty(permissions["payment_links"]) &&
    permissions["discount_codes"] &&
    permissions["limited_time_offer"] &&
    permissions["custom_mails"] &&
    permissions["integrations"] &&
    permissions["traffic_analysis"] &&
    permissions[RESOURCE_KEYS.SECTIONS.WHATSAPP_BROADCASTS] &&
    permissions[RESOURCE_KEYS.SECTIONS.MEDIA_CREATIVES] &&
    permissions[RESOURCE_KEYS.SECTIONS.SEO];

  const hideTransactions =
    permissions["manage_transactions"] &&
    is_empty(permissions["manage_transactions"]);
  const isAllTransactionHidden = orgPermissions.isNavigationItemHidden(
    RESOURCE_KEYS.SUBSECTIONS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS
  );

  const tabs = [
    {
      label: "Home",
      icon: <HomeRoundedIcon />,
      path: "/mobile-home",
    },
    {
      label: "Listings",
      icon: <ListingsIcon className="menu-item-selected" />,
      path: "/ScheduleList",
      hidden: hideListings,
      ...LISTING_CONFIG,
    },
    {
      label: "Dashboard",
      icon: <DashboardRoundedIcon />,
      path: "/Dashboard",
      hidden: hideDashboard,
      ...DASHBOARD_CONFIG,
    },
    {
      label: "Sales",
      icon: <MonetizationOnRoundedIcon />,
      path: "/sales-and-marketing",
      hidden: hideSales,
      ...SALES_PAGES_CONFIG,
    },
    {
      label: "Transactions",
      icon: <SwapHorizRoundedIcon />,
      path: isAllTransactionHidden
        ? `/${api.manage_subscriptions_list}`
        : `/${api.transaction_list}`,
      hidden: hideTransactions,
      ...TRANSACTIONS_CONFIG,
    },
  ];

  return tabs.filter((tab) => !tab.hidden);
};
