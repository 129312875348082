import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, Box, Tooltip } from "@my-scoot/component-library-legacy";
import classnames from "classnames";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useSelector } from "react-redux";

import useStyles from "./StatsCard.styles";
import PeopleIcon from "@material-ui/icons/People";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const StatsCard = ({
  color = "primary",
  title,
  statsNumber,
  showLinkText = true,
  linkText = "View Chart",
  onClick = () => null,
  Icon = PeopleIcon,
  tooltip = null,
  className,
  filterDays,
  isSubText = false,
  classes = {},
}) => {
  const isMediumSize = useMediaQuery("(min-width: 767px)");
  const isLargeSize = useMediaQuery("(min-width: 1042px)");
  const isSidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
  const showFullSizeCard = isSidebarOpen ? isLargeSize : isMediumSize;

  const statsNumberClass = classes.statsNumberClass ?? "";
  const statsTitleClass = classes.statsTitleClass ?? "";
  const textColorClass = classes.textColorClass ?? "";
  const iconClass = classes.iconClass ?? "";

  const styles = useStyles({ color });
  const [open, setOpen] = useState(false);

  const onCardClick = () => {
    if (open) setOpen(false);
    else onClick();
  };

  const onOpenTooltip = (event) => {
    event.stopPropagation();
    setOpen(true);
  };

  return showFullSizeCard ? (
    <Card
      className={[styles.statsCardWrapper, className].join(" ")}
      elevation={0}
    >
      <Icon className={`${styles.statsIcon} ${iconClass} ${textColorClass}`} />
      <Box className={styles.statsDetails}>
        <Box className={styles.titleSubtitleWrapper}>
          <Box className={styles.titleWrapper}>
            <Typography
              className={`${styles.statsTitle} ${textColorClass}`}
              component="span"
            >
              {title}
            </Typography>
            {!!tooltip && (
              <Tooltip
                title={tooltip}
                color="primary"
                arrow
                className={` ${textColorClass}`}
              >
                <InfoOutlinedIcon className={styles.toolTipIcon} />
              </Tooltip>
            )}
          </Box>
          {filterDays && (
            <Typography
              className={`${styles.statsSubTitle}  ${textColorClass}`}
              component="span"
            >
              Last {filterDays} Days
            </Typography>
          )}
        </Box>

        <Typography
          className={`${styles.statsNumber}  ${textColorClass}`}
          component="span"
        >
          {statsNumber}
        </Typography>
      </Box>

      {showLinkText && (
        <Typography
          className={`${styles.statsLink} ${textColorClass}`}
          onClick={onClick}
          component="span"
        >
          {linkText}
        </Typography>
      )}
    </Card>
  ) : (
    <Card
      className={classnames(
        styles.card_wrapper_mb,
        className,
        "row no-gutters"
      )}
      elevation={0}
      onClick={onCardClick}
    >
      <Box className={`${styles.icon_wrapper_mb}`}>
        <Icon
          className={`${styles.stats_icon_mb} ${iconClass} ${textColorClass}`}
        />
        {!!tooltip && (
          <div className={styles.info_icon_wrapper_mb}>
            <Tooltip
              title={tooltip}
              color="primary"
              arrow
              className={` ${textColorClass}`}
            >
              <InfoOutlinedIcon
                onClick={onOpenTooltip}
                className={styles.toolTipIcon}
              />
            </Tooltip>
          </div>
        )}
      </Box>

      <Typography
        className={`col-11 ${styles.statsNumber} ${textColorClass} ${statsNumberClass}`}
        component="div"
      >
        {statsNumber}
      </Typography>

      <Typography
        className={`col-12 ${styles.stats_title_mb} ${textColorClass} ${statsTitleClass}`}
        component="div"
      >
        {title}
      </Typography>
      {isSubText && (
        <Typography
          className={`${styles.stats_subtitle_mb} ${textColorClass}`}
          component="span"
        >
          Last {filterDays} Days
        </Typography>
      )}
    </Card>
  );
};

export default StatsCard;

StatsCard.propTypes = {
  color: PropTypes.any,
  title: PropTypes.string,
  statsNumber: PropTypes.string,
  showLinkText: PropTypes.bool,
  linkText: PropTypes.string,
  onClick: PropTypes.func,
  Icon: PropTypes.any,
  tooltip: PropTypes.any,
  className: PropTypes.string,
};
