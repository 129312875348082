import React from "react";
import { Input } from "@my-scoot/component-library-legacy";
import { InputAdornment, makeStyles } from "@material-ui/core";
import { is_empty } from "utils/validations";
import moduleStyle from "./ExlyMoneyInput.module.css";

const useStyles = makeStyles((theme) => ({
  currencySymbol: {
    height: "100%",
    width: "32px",
    maxHeight: "none",
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
    marginRight: "0",
    marginLeft: "-1.5px",
    borderRadius: "4px 0 0 4px",
    background: "#D9D9D9",
    color: theme?.palette?.secondary?.main,
    "& p": {
      color: "inherit",
    },
  },
}));

const ExlyMoneyInput = (props) => {
  const classes = useStyles();
  const {
    fieldLabel,
    symbol,
    input = {},
    meta = {},
    onChange,
    ...rest
  } = props;

  return (
    <>
      {!is_empty(fieldLabel) && (
        <div className={`mb-1`}>
          <span className={`${moduleStyle.labelField} ${fieldLabel.textClass}`}>
            {fieldLabel.text}
          </span>
          {fieldLabel.required && <span className={`text-danger`}>*</span>}
        </div>
      )}
      <Input
        startAdornment={
          <InputAdornment className={classes.currencySymbol} position="start">
            {symbol}
          </InputAdornment>
        }
        error={meta.error && meta.touched}
        helperText={meta.error && meta.touched ? meta.error : null}
        {...input}
        {...rest}
        onChange={(e) => {
          input.onChange && input.onChange(e);
          if (onChange) {
            onChange(e.target.value, input.name);
          }
        }}
      />
    </>
  );
};

export default ExlyMoneyInput;
