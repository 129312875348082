import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    label: {
      marginBottom: "8px",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "17px",
      color: theme?.palette?.secondary?.main,
    },
    mandatory: {
      color: theme?.palette?.basic?.danger_red,
    },
    error: {
      marginTop: "7.28px",
      fontSize: "11px",
      color: theme?.palette?.basic?.danger_red,
    },
  }),
  { name: "CreatorTool" }
);
