import { Paper, TextField } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { Button } from "@my-scoot/component-library-legacy";
import React from "react";
import { useStyles } from "./index.styles";

const ExlyAutoComplete = (props) => {
  const {
    input,
    meta,
    onChange,
    label,
    inputProps,
    showApplyCta = true,
    ...rest
  } = props;

  const classes = useStyles();

  const { className, limit = 50 } = rest;

  const CustomPaper = React.useCallback(
    ({ children, ...restProps }) => (
      <Paper {...restProps}>
        {children}
        <Button className={classes.cta} size="thin" fullWidth>
          Apply
        </Button>
      </Paper>
    ),
    []
  );

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit,
  });

  return (
    <div className={className}>
      {label && <div className={classes.label}>{label}</div>}
      <Autocomplete
        classes={{
          option: classes.autocompleteOption,
          groupLabel: classes.autocompleteGroupLabel,
          input: classes.input,
          inputRoot: classes.inputRoot,
          inputFocused: classes.inputFocused,
          endAdornment: classes.endAdornment,
          popupIndicator: classes.popupIndicator,
        }}
        filterOptions={filterOptions}
        {...(showApplyCta ? { PaperComponent: CustomPaper } : {})}
        popupIcon={<KeyboardArrowDownIcon />}
        renderInput={(params) => (
          <TextField
            error={meta.error && meta.touched}
            variant="outlined"
            {...inputProps}
            {...params}
          />
        )}
        {...input}
        {...rest}
        onChange={(e, value) => {
          input.onChange(value);
          if (onChange) onChange(value);
        }}
      />
      {meta.error && meta.touched && (
        <div className={classes.error}>{meta.error}</div>
      )}
    </div>
  );
};

export default ExlyAutoComplete;
