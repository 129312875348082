import React from "react";
import classnames from "classnames";
import { MobilePopover } from "@my-scoot/component-library-legacy";
import ButtonBase from "@material-ui/core/ButtonBase";
import style from "./ActionDrawer.module.css";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";

/*
Mobile actions (opening in mobile drawer)
*/

function ActionDrawer({ open, onClose, actions = [], classes = {} }) {
  return (
    <MobilePopover open={open} onClose={onClose}>
      <div className={classnames(style.wrap, classes.wrapper)}>
        {actions.map(({ label, icon, onClick, disabled, className }) => (
          <ButtonBase
            className={classnames(
              style.action,
              disabled && style.disabled,
              classes.action,
              className
            )}
            key={label}
            onClick={onClick}
            disabled={disabled}
          >
            {icon}
            <div>{label}</div>
          </ButtonBase>
        ))}
      </div>
    </MobilePopover>
  );
}

export default withComponentLibraryTheme(ActionDrawer);
