import React from "react";
import EmailRecipientFieldV2 from "ui/Fields/EmailRecipientFieldV2";

const ExlyEmailRecipientInput = (props) => {
  const { helperText, meta, input, ...restProps } = props;
  const { error, touched } = meta;

  return (
    <EmailRecipientFieldV2
      error={error}
      helperText={error && touched ? error : helperText}
      {...input}
      {...restProps}
    />
  );
};

export default ExlyEmailRecipientInput;
