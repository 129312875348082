import React from "react";
import classnames from "classnames";
import { RadioGroup } from "@my-scoot/component-library-legacy";
import { useStyles } from "./index.styles";

const ExlyRadioGroup = (props) => {
  const classes = useStyles();
  const {
    label,
    labelClassName,
    required,
    input,
    meta,
    onChange,
    helperText,
    ...rest
  } = props;
  const { options } = rest;
  const { value } = input;
  const selected = options?.find((option) => option.value === value);

  return (
    <div>
      {label && (
        <div className={classnames(classes.label, labelClassName)}>
          {label}
          {required && <span className={classes.mandatory}>*</span>}
        </div>
      )}
      <RadioGroup
        {...input}
        {...rest}
        selected={selected}
        onChange={({ value }) => {
          input.onChange(value);
          if (onChange) onChange(value);
        }}
        radioProps={{ error: (meta.error || meta.submitError) && meta.touched }}
      />
      {(meta.error || meta.submitError) && meta.touched && (
        <div className={classes.error}>
          {meta.error || meta.submitError || helperText}
        </div>
      )}
    </div>
  );
};

export default ExlyRadioGroup;
