import { useState, useEffect, useCallback } from "react";

import local_storage_keys from "constants/LocalStorage.constants";
import { fetchFeatureOnboardingFlag } from "./FeatureOnboarding.helpers";
import { updateFeatureOnboardingFlagV2 } from "features/Common/FeatureOnboardingV2/utils/FeatureOnboardingV2.utils";
import { logError } from "utils/error";

/** @deprecated create a new hook for the following implementation
 * --List is issues found :
 * 1 This one calls the fetchFeatureFlag api in useEffect.
 * 2 The fetch feature api is being called until the value of the key is true.
 *
 * the above cause multiple network calls for the same key, in the following scenerios.
 * 1 If two sibling component call it for same key ->(Component called at same time).
 * 2 If a key is false for all creator (e.g hasSeenWelcomeModal).
 *   2.1 undefined (as the entry does not exist in db)
 *   2.2 false (as the use has not seen the modal yet).
 * 3 Until the key is updated as true, multiple api calls will happen on the same screen if a parent and it's children component are calling this hook for the same key
 *
 */
const useFeatureOnboardingChecker = ({
  featureKey,
  successCallback = () => {},
}) => {
  const [isOnboarded, setIsOnboarded] = useState(true);
  const [flagLoading, setFlagLoading] = useState(false);

  // flag value before flag update call
  const [isOnboardedPrevVal, setIsOnboardedPrevVal] = useState(true);

  useEffect(() => {
    if (!featureKey) return;

    const allFeaturesData = JSON.parse(
      localStorage.getItem(local_storage_keys.IS_FEATURE_ONBOARDED)
    );
    if (allFeaturesData && allFeaturesData[featureKey]) return;

    setFlagLoading(true);

    fetchFeatureOnboardingFlag(featureKey)
      .then((response) => {
        const updatedData = {
          ...(allFeaturesData || {}),
          [featureKey]: response,
        };
        localStorage.setItem(
          local_storage_keys.IS_FEATURE_ONBOARDED,
          JSON.stringify(updatedData)
        );
        setIsOnboarded(response);
        setIsOnboardedPrevVal(response);
        successCallback(response);
      })
      .catch((error) => {
        logError({
          error,
          occuredAt:
            "fetchFeatureOnboardingFlag in src/common/Components/FeatureOnboarding/useFeatureOnboardingChecker.js",
          when: "calling feature update api",
        });
      })
      .finally(() => setFlagLoading(false));
  }, [featureKey]);

  const handleFeatureOnboardChange = useCallback(
    async (value) => {
      try {
        const updatedData = await updateFeatureOnboardingFlagV2({
          featureKey,
          value,
        });
        if (featureKey in updatedData) setIsOnboarded(value);
      } catch (error) {
        logError({
          error,
          occuredAt:
            "handleFeatureOnboardChange in src/common/Components/FeatureOnboarding/useFeatureOnboardingChecker.js",
          when: "calling feature update api",
        });
      }
    },
    [featureKey]
  );

  return {
    isOnboarded,
    isOnboardedPrevVal,
    flagLoading,
    handleFeatureOnboardChange,
  };
};

export default useFeatureOnboardingChecker;
